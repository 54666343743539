import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faExternalLink,
  faArrowLeft,
} from "@fortawesome/pro-light-svg-icons";
import HelpcenterArticlesCard, {
  HelpcenterArticlesCardProps,
} from "./HelpcenterArticlesCard";
import FaqSimilarTopicsCard, {
  FaqSimilarTopicsCardProps,
} from "./FaqSimilarTopicsCard";
import { AnchorButton } from "@casasoft/styleguide/components/forms/Button";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
} from "@casasoft/styleguide/components/card";
import { useRef, useState, useEffect, ReactNode } from "react";

export interface HelpcenterProps {
  onClose: () => void;
  faqArticles: HelpcenterArticlesCardProps["articles"];
  activeArticle?: {
    item_key: string;
    title: ReactNode;
    content: ReactNode;
    url: string;
  };
  isLoading: boolean;
  pathname: string;
  similiarTopics: FaqSimilarTopicsCardProps["similiarTopics"];
  onTopicSelect: FaqSimilarTopicsCardProps["onTopicSelect"];
  onArticleSelect: HelpcenterArticlesCardProps["onArticleSelect"];
}

const Helpcenter = ({
  onClose,
  faqArticles,
  activeArticle,
  isLoading,
  pathname,
  similiarTopics,
  onTopicSelect,
  onArticleSelect,
}: HelpcenterProps) => {
  const { t } = useTranslation();

  const [helpcenterHistory, setHelpcenterHistory] = useState<
    { articleId?: string; selectedTopic?: string }[]
  >([]);

  const [storedPathname, setStoredPathname] = useState(pathname);
  const [articleSelected, setArticleSelected] = useState(false);

  const latestItemInHistory = helpcenterHistory[
    helpcenterHistory.length - 1
  ] as typeof helpcenterHistory[number] | undefined;

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // check for page change
    if (pathname !== storedPathname) {
      // if an article is selected, push active article into history and delete selected topics
      if (activeArticle) {
        setHelpcenterHistory([
          {
            articleId: activeArticle.item_key,
            selectedTopic: undefined,
          },
        ]);
      } else {
        // if no article is selected - just delete history on page change
        setHelpcenterHistory([]);
      }
      setStoredPathname(pathname);
    }
  }, [
    pathname,
    storedPathname,
    faqArticles,
    latestItemInHistory,
    activeArticle,
  ]);
  return (
    <div className="helpcenter tw-relative tw-h-full tw-pb-12">
      <div className="tw-flex tw-justify-between tw-items-center tw-p-4 tw-font-thin tw-leading-4 tw-w-full tw-border-solid tw-border-0 tw-border-b tw-border-cs-shade-300">
        {!!helpcenterHistory.length && (
          <div
            className="tw-flex tw-items-center tw-cursor-pointer tw-w-1/3 tw-text-cs-shade-500"
            onClick={() => {
              const newSelectedArticles = helpcenterHistory.slice(
                0,
                helpcenterHistory.length - 1
              );
              onTopicSelect(
                newSelectedArticles[newSelectedArticles.length - 1]
                  ?.selectedTopic
              );
              onArticleSelect(
                newSelectedArticles[newSelectedArticles.length - 1]?.articleId
              );
              if (!newSelectedArticles.length) {
                setArticleSelected(false);
              } else {
                if (
                  newSelectedArticles[newSelectedArticles.length - 1]?.articleId
                ) {
                  setArticleSelected(true);
                }
              }
              setHelpcenterHistory(newSelectedArticles);
            }}
          >
            <FontAwesomeIcon
              className="tw-mr-0.5 tw-h-3"
              fixedWidth
              icon={faArrowLeft}
            />
            {t("Back")}
          </div>
        )}
        <div className="tw-w-1/3 tw-text-center tw-text-cs-shade-500 tw-flex items-center">
          {t("Helpcenter")}
        </div>
        <div className="tw-w-1/3 tw-text-right">
          <div
            className="tw-text-cs-shade-500 tw-flex tw-items-center tw-justify-end tw-cursor-pointer"
            onClick={() => {
              onClose();
            }}
          >
            <FontAwesomeIcon
              className=""
              fixedWidth
              icon={faAngleDoubleRight}
            />
          </div>
        </div>
      </div>
      <div
        className="tw-py-4 tw-px-2 tw-overflow-y-scroll tw-h-full tw-pb-20"
        ref={scrollContainerRef}
      >
        {activeArticle && articleSelected && (
          <div className="wp-integrated-content helpcenter-article">
            <Card loading={isLoading}>
              <CardHeader>
                <div className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-pr-5">
                  {activeArticle.title}
                </div>
              </CardHeader>
              <CardBody>
                <div className="tw-text-sm tw-max-w-full child:tw-max-w-full">
                  {activeArticle.content}
                </div>
              </CardBody>
              <a
                href={activeArticle.url}
                target="_blank"
                className="hover:tw-bg-cs-shade-300 tw-p-4 tw-flex tw-items-center tw-justify-between tw-border-t-2 tw-border-solid tw-rounded-b tw-border-0 tw-border-cs-shade-50 tw-transition-all"
                rel="noreferrer"
              >
                <div className="tw-whitespace-nowrap tw-text-ellipsis">
                  {t("View article in Helpcenter")}
                </div>
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </Card>
            <div className="tw-mb-4" />
          </div>
        )}
        <HelpcenterArticlesCard
          articles={faqArticles}
          activeTopic={similiarTopics?.activeTopic.name}
          onArticleSelect={(newArticle) => {
            setArticleSelected(true);
            scrollContainerRef.current?.scrollTo({
              behavior: "smooth",
              top: 0,
            });

            setHelpcenterHistory((helpcenterHistory) => [
              ...helpcenterHistory,
              {
                articleId: newArticle,
                selectedTopic:
                  latestItemInHistory?.selectedTopic ||
                  similiarTopics?.activeTopic.value,
              },
            ]);
            onArticleSelect(newArticle);
          }}
          isLoading={isLoading}
        />

        <div className="tw-mb-4" />
        <FaqSimilarTopicsCard
          isLoading={isLoading}
          similiarTopics={similiarTopics}
          onTopicSelect={(newTopic) => {
            scrollContainerRef.current?.scrollTo({
              behavior: "smooth",
              top: 0,
            });
            onTopicSelect(newTopic);
            setArticleSelected(false);
            onArticleSelect(undefined);
            setHelpcenterHistory((helpcenterHistory) => [
              ...helpcenterHistory,
              {
                articleId: undefined,
                selectedTopic: newTopic,
              },
            ]);
          }}
        />
      </div>
      <div className="tw-z-10 tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-[64px] tw-px-4 tw-flex tw-justify-between tw-items-center tw-font-thin tw-border-solid tw-border-0 tw-border-t tw-border-cs-shade-300 tw-bg-cs-shade-100">
        <div className="-tw-ml-4">
          <AnchorButton
            href="https://get.teamviewer.com/casasoft"
            target="_blank"
          >
            <div className="tw-flex tw-items-center">
              <span>Teamviewer</span>
              <FontAwesomeIcon
                className="tw-ml-1"
                fixedWidth
                icon={faExternalLink}
                size="xs"
              />
            </div>
          </AnchorButton>
        </div>
        <div>
          <AnchorButton
            isPrimary
            isOutline
            href="https://casasoft.ch/support"
            target="_blank"
          >
            {t("Create Support Ticket")}
          </AnchorButton>
        </div>
      </div>
    </div>
  );
};

export default Helpcenter;
